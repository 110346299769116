var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"-my-2 sm:-mx-6 lg:-mx-8"},[_c('div',{staticClass:"py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"},[_c('div',{staticClass:"border border-gray-200 sm:rounded-lg"},[_c('table',{staticClass:"rounded-lg min-w-full divide-y divide-gray-200"},[_c('thead',{staticClass:"bg-gray-50 overflow-hidden"},[_c('tr',_vm._l((_vm.head),function(label,labelIndex){return _c('th',{key:labelIndex,staticClass:"overflow-hidden rounded-lg px-6 py-3 text-left text-sm font-medium text-gray-500 capitalize tracking-wider",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(label.label)+" ")])}),0)]),_c('tbody',{staticClass:"bg-white divide-y divide-gray-200"},_vm._l((_vm.chunks[_vm.page - 1]),function(element,elementIndex){return _c('tr',{key:elementIndex},_vm._l((_vm.head),function(label,labelIndex){return _c('td',{key:labelIndex,staticClass:"px-6 py-4 whitespace-nowrap"},[(
                    label.key in element &&
                      ((Array.isArray(element[label.key]) &&
                        element[label.key].length !== 0 &&
                        !element[label.key][0].action) ||
                        (!Array.isArray(element[label.key]) &&
                          !element[label.key].action))
                  )?_c('div',{staticClass:"text-sm w-full text-left font-medium text-gray-900"},[(!Array.isArray(element[label.key]))?_c('div',[_c('span',{staticClass:"inline-block",class:element[label.key].class},[_c('span',{staticClass:"whitespace-normal break-word"},[_vm._v(_vm._s(element[label.key].label))])])]):_c('div',{class:Array.isArray(element[label.key]) &&
                        'flex flex-col justify-center items-start'},_vm._l((element[label.key]),function(vertical,verticalIndex){return _c('span',{key:verticalIndex,staticClass:"inline-block",class:vertical.class},[_c('span',{staticClass:"whitespace-normal break-word"},[_vm._v(_vm._s(vertical.label))])])}),0)]):_vm._e(),(
                    label.key in element &&
                      ((Array.isArray(element[label.key]) &&
                        element[label.key].length !== 0 &&
                        element[label.key][0].action) ||
                        (!Array.isArray(element[label.key]) &&
                          element[label.key].action))
                  )?_c('div',{staticClass:"text-sm w-full text-left font-medium text-gray-900"},[(!Array.isArray(element[label.key]))?_c('div',[_c('button',{class:element[label.key].class,on:{"click":function($event){element[label.key].action}}},[_vm._v(" "+_vm._s(element[label.key].label)+" ")])]):_c('div',{class:Array.isArray(element[label.key]) &&
                        'flex flex-col justify-center items-start'},[(
                        element[label.key].filter(function (e) { return e.dropdown; }).length != 0
                      )?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(
                        function () {
                          _vm.dropdown[
                            (elementIndex + 1) * _vm.page - 1
                          ].active = false;
                        }
                      ),expression:"\n                        () => {\n                          dropdown[\n                            (elementIndex + 1) * page - 1\n                          ].active = false;\n                        }\n                      "}],staticClass:"relative w-full mb-2 inline-block text-left"},[_c('button',{staticClass:"inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none",attrs:{"type":"button","id":"menu-button","aria-expanded":"true","aria-haspopup":"true"},on:{"click":function($event){_vm.toggleElement((elementIndex + 1) * _vm.page - 1)}}},[_vm._v(" opciones "),_c('svg',{staticClass:"-mr-1 ml-2 h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z","clip-rule":"evenodd"}})])]),(_vm.dropdown[(elementIndex + 1) * _vm.page - 1].active)?_c('div',{staticClass:"absolute right-0 z-10 mt-2 w-56 origin-top-right border border-gray-200 rounded-md bg-white shadow-xl focus:outline-none",attrs:{"role":"menu","aria-orientation":"vertical","aria-labelledby":"menu-button","tabindex":"-1"}},[_c('div',{staticClass:"p-2",attrs:{"role":"none"}},_vm._l((element[
                              label.key
                            ].filter(function (e) { return e.dropdown; })),function(vertical,verticalIndex){return _c('button',{key:'dropdown-' + verticalIndex,staticClass:"block",class:vertical.class,attrs:{"role":"menuitem","tabindex":"-1"},on:{"click":vertical.action}},[_vm._v(" "+_vm._s(vertical.label)+" ")])}),0)]):_vm._e()]):_vm._e(),_vm._l((element[
                        label.key
                      ].filter(function (e) { return !e.dropdown; })),function(vertical,verticalIndex){return _c('button',{key:verticalIndex,class:vertical.class,on:{"click":vertical.action}},[_vm._v(" "+_vm._s(vertical.label)+" ")])})],2)]):_vm._e()])}),0)}),0)]),(!_vm.body || _vm.body.length == 0)?_c('div',{staticClass:"bg-white flex justify-center items-center h-80"},[(_vm.loading)?_c('svg',{staticClass:"animate-spin -ml-1 mr-3 h-10 w-10 text-gray-900",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24"}},[_c('circle',{staticClass:"opacity-25",attrs:{"cx":"12","cy":"12","r":"10","stroke":"currentColor","stroke-width":"4"}}),_c('path',{staticClass:"opacity-75",attrs:{"fill":"currentColor","d":"M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"}})]):_c('span',[_vm._v("Sin elementos")])]):_vm._e(),_c('div',{staticClass:"rounded-b-lg px-6 py-3 w-full bg-white border-t border-gray-200 flex flex-row justify-between items-center"},[_c('div',[_vm._l((_vm.pagination[0]),function(p){return _c('button',{key:p,staticClass:"rounded-md text-sm mr-2 px-3 py-1",class:p === _vm.page
                  ? 'bg-gray-900 text-white'
                  : 'bg-gray-100 hover:bg-gray-200',on:{"click":function () {
                  _vm.page = p;
                }}},[_vm._v(" "+_vm._s(p)+" ")])}),(_vm.pagination[0].length !== 0)?_c('span',{staticClass:"mr-6 ml-4"},[_vm._v("...")]):_vm._e(),_vm._l((_vm.pagination[1]),function(p){return _c('button',{key:p,staticClass:"rounded-md text-sm mr-2 px-3 py-1",class:p === _vm.page
                  ? 'bg-gray-900 text-white'
                  : 'bg-gray-100 hover:bg-gray-200',on:{"click":function () {
                  _vm.page = p;
                }}},[_vm._v(" "+_vm._s(p)+" ")])}),(_vm.pagination[2].length !== 0)?_c('span',{staticClass:"mr-6 ml-4"},[_vm._v("...")]):_vm._e(),_vm._l((_vm.pagination[2]),function(p){return _c('button',{key:p,staticClass:"rounded-md text-sm mr-2 px-3 py-1",class:p === _vm.page
                  ? 'bg-gray-900 text-white'
                  : 'bg-gray-100 hover:bg-gray-200',on:{"click":function () {
                  _vm.page = p;
                }}},[_vm._v(" "+_vm._s(p)+" ")])})],2),_c('div',{staticClass:"text-sm text-gray-700"},[_vm._v("total "+_vm._s(_vm.body.length))])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }