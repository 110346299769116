import authAxios from '@/utils/authAxios';

export default {
  setContactData: ({ id, contact, phone }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/patient/set-contact', {
          id,
          contact,
          phone,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  savePatientFormAdmin: ({
    id,
    reason,
    mental_tests,
    clinic_history,
    background_check,
    substance_use,
    alternatives_medicine,
    suicidal_risk,
    other_diagnoses,
    pharmacological_indications,
    psychotherapy,
    hospital,
    hospitalization,
    diagnostic,
    is_ges,
    problem_ges,
    warranty,
  }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/patient/save-form-admin', {
          id,
          reason,
          mental_tests,
          clinic_history,
          background_check,
          substance_use,
          alternatives_medicine,
          suicidal_risk,
          other_diagnoses,
          pharmacological_indications,
          psychotherapy,
          hospital,
          hospitalization,
          diagnostic,
          is_ges,
          problem_ges,
          warranty,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  savePatientFormDoctor: ({
    id,
    reason,
    mental_tests,
    clinic_history,
    background_check,
    substance_use,
    alternatives_medicine,
    suicidal_risk,
    other_diagnoses,
    pharmacological_indications,
    psychotherapy,
    hospital,
    hospitalization,
  }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/patient/save-form-doctor', {
          id,
          reason,
          mental_tests,
          clinic_history,
          background_check,
          substance_use,
          alternatives_medicine,
          suicidal_risk,
          other_diagnoses,
          pharmacological_indications,
          psychotherapy,
          hospital,
          hospitalization,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  savePreForm: ({
    id = null,
    patient_id,
    marital_status,
    living,
    educational_level,
    job_occupation,
    diseases,
    specialty,
    marital_status_text,
    living_text,
    educational_level_text,
    job_occupation_text,
    diseases_text,
    specialty_text,
  }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/patient/save-preform', {
          id,
          patient_id,
          marital_status,
          living,
          educational_level,
          job_occupation,
          diseases,
          specialty,
          marital_status_text,
          living_text,
          educational_level_text,
          job_occupation_text,
          diseases_text,
          specialty_text,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getPreForm: (id = null) => {
    return new Promise((resolve, reject) => {
      authAxios
        .get(process.env.VUE_APP_BACKEND_URL + `/patient/get-preform/${id}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getPatientFromDoctor: id => {
    return new Promise((resolve, reject) => {
      authAxios
        .get(
          process.env.VUE_APP_BACKEND_URL +
            `/patient/get-patient-from-doctor/${id}`
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getPatientsFromDoctor: () => {
    return new Promise((resolve, reject) => {
      authAxios
        .get(
          process.env.VUE_APP_BACKEND_URL + `/patient/get-patients-from-doctor/`
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getPatients: () => {
    return new Promise((resolve, reject) => {
      authAxios
        .get(process.env.VUE_APP_BACKEND_URL + '/patient')
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /**
   ************************************************************************************************************************
   */
  setPatient: id => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/set_patient', {
          id,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
