<template>
  <div class="flex flex-col">
    <div class="-my-2 sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="border border-gray-200 sm:rounded-lg">
          <table class="rounded-lg min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50 overflow-hidden">
              <tr>
                <th
                  v-for="(label, labelIndex) in head"
                  :key="labelIndex"
                  scope="col"
                  class="overflow-hidden rounded-lg px-6 py-3 text-left text-sm font-medium text-gray-500 capitalize tracking-wider"
                >
                  {{ label.label }}
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr
                v-for="(element, elementIndex) in chunks[page - 1]"
                :key="elementIndex"
              >
                <td
                  v-for="(label, labelIndex) in head"
                  :key="labelIndex"
                  class="px-6 py-4 whitespace-nowrap"
                >
                  <div
                    v-if="
                      label.key in element &&
                        ((Array.isArray(element[label.key]) &&
                          element[label.key].length !== 0 &&
                          !element[label.key][0].action) ||
                          (!Array.isArray(element[label.key]) &&
                            !element[label.key].action))
                    "
                    class="text-sm w-full text-left font-medium text-gray-900"
                  >
                    <div v-if="!Array.isArray(element[label.key])">
                      <span
                        class="inline-block"
                        :class="element[label.key].class"
                      >
                        <span class="whitespace-normal break-word">{{
                          element[label.key].label
                        }}</span></span
                      >
                    </div>
                    <div
                      v-else
                      :class="
                        Array.isArray(element[label.key]) &&
                          'flex flex-col justify-center items-start'
                      "
                    >
                      <span
                        class="inline-block"
                        v-for="(vertical, verticalIndex) in element[label.key]"
                        :key="verticalIndex"
                        :class="vertical.class"
                      >
                        <span class="whitespace-normal break-word">{{
                          vertical.label
                        }}</span>
                      </span>
                    </div>
                  </div>
                  <div
                    v-if="
                      label.key in element &&
                        ((Array.isArray(element[label.key]) &&
                          element[label.key].length !== 0 &&
                          element[label.key][0].action) ||
                          (!Array.isArray(element[label.key]) &&
                            element[label.key].action))
                    "
                    class="text-sm w-full text-left font-medium text-gray-900"
                  >
                    <div v-if="!Array.isArray(element[label.key])">
                      <button
                        @click="element[label.key].action"
                        :class="element[label.key].class"
                      >
                        {{ element[label.key].label }}
                      </button>
                    </div>
                    <div
                      v-else
                      :class="
                        Array.isArray(element[label.key]) &&
                          'flex flex-col justify-center items-start'
                      "
                    >
                      <div
                        v-if="
                          element[label.key].filter(e => e.dropdown).length != 0
                        "
                        v-click-outside="
                          () => {
                            dropdown[
                              (elementIndex + 1) * page - 1
                            ].active = false;
                          }
                        "
                        class="relative w-full mb-2 inline-block text-left"
                      >
                        <button
                          type="button"
                          @click="toggleElement((elementIndex + 1) * page - 1)"
                          class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none"
                          id="menu-button"
                          aria-expanded="true"
                          aria-haspopup="true"
                        >
                          opciones
                          <svg
                            class="-mr-1 ml-2 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                        <div
                          v-if="dropdown[(elementIndex + 1) * page - 1].active"
                          class="absolute right-0 z-10 mt-2 w-56 origin-top-right border border-gray-200 rounded-md bg-white shadow-xl focus:outline-none"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="menu-button"
                          tabindex="-1"
                        >
                          <div class="p-2" role="none">
                            <button
                              v-for="(vertical, verticalIndex) in element[
                                label.key
                              ].filter(e => e.dropdown)"
                              :key="'dropdown-' + verticalIndex"
                              :class="vertical.class"
                              class="block"
                              role="menuitem"
                              tabindex="-1"
                              @click="vertical.action"
                            >
                              {{ vertical.label }}
                            </button>
                          </div>
                        </div>
                      </div>
                      <button
                        v-for="(vertical, verticalIndex) in element[
                          label.key
                        ].filter(e => !e.dropdown)"
                        :key="verticalIndex"
                        :class="vertical.class"
                        @click="vertical.action"
                      >
                        {{ vertical.label }}
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-if="!body || body.length == 0"
            class="bg-white flex justify-center items-center h-80"
          >
            <svg
              v-if="loading"
              class="animate-spin -ml-1 mr-3 h-10 w-10 text-gray-900"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <span v-else>Sin elementos</span>
          </div>
          <div
            class="rounded-b-lg px-6 py-3 w-full bg-white border-t border-gray-200 flex flex-row justify-between items-center"
          >
            <div>
              <button
                v-for="p in pagination[0]"
                :key="p"
                @click="
                  () => {
                    page = p;
                  }
                "
                class="rounded-md text-sm mr-2 px-3 py-1"
                :class="
                  p === page
                    ? 'bg-gray-900 text-white'
                    : 'bg-gray-100 hover:bg-gray-200'
                "
              >
                {{ p }}
              </button>
              <span v-if="pagination[0].length !== 0" class="mr-6 ml-4"
                >...</span
              >
              <button
                v-for="p in pagination[1]"
                :key="p"
                @click="
                  () => {
                    page = p;
                  }
                "
                class="rounded-md text-sm mr-2 px-3 py-1"
                :class="
                  p === page
                    ? 'bg-gray-900 text-white'
                    : 'bg-gray-100 hover:bg-gray-200'
                "
              >
                {{ p }}
              </button>
              <span v-if="pagination[2].length !== 0" class="mr-6 ml-4"
                >...</span
              >
              <button
                v-for="p in pagination[2]"
                :key="p"
                @click="
                  () => {
                    page = p;
                  }
                "
                class="rounded-md text-sm mr-2 px-3 py-1"
                :class="
                  p === page
                    ? 'bg-gray-900 text-white'
                    : 'bg-gray-100 hover:bg-gray-200'
                "
              >
                {{ p }}
              </button>
            </div>
            <div class="text-sm text-gray-700">total {{ body.length }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'Table',
  props: {
    head: {
      type: Array,
      default: () => [],
    },
    body: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chunkSize: 5,
      page: 1,
      dropdown: [],
    };
  },
  created() {
    this.setDropdown();
  },
  methods: {
    toggleElement(index) {
      this.dropdown[index].active = !this.dropdown[index].active;
    },
    setDropdown() {
      this.dropdown = [];
      this.body.forEach(() => {
        this.dropdown.push({
          active: false,
        });
      });
    },
  },
  watch: {
    body() {
      this.setDropdown();
    },
  },
  computed: {
    pagination() {
      const total = this.chunks.length;
      const left = 3;
      const right = 3;
      let output = [[], [], []];
      //middle section
      for (let i = this.page - left; i <= this.page + right; i++) {
        if (i > 0 && i <= total) {
          output[1].push(i);
        }
      }
      // left section
      for (let i = 1; i <= 2; i++) {
        if (!output[1].includes(i) && i <= total) {
          output[0].push(i);
        }
      }

      // right section
      for (let i = total - 1; i <= total; i++) {
        if (!output[1].includes(i) && i > 0) {
          output[2].push(i);
        }
      }

      return output;
    },
    chunks() {
      let output = [];
      for (let i = 0; i < this.body.length; i += this.chunkSize) {
        const chunk = this.body.slice(i, i + this.chunkSize);
        output.push(chunk);
      }
      return output;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
