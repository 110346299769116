<template>
  <div class="w-full">
    <input-search v-model="search" />
    <table-component
      :loading="loading"
      :head="[
        { key: 'patient', label: 'Paciente' },
        { key: 'identity', label: 'Datos' },
        { key: 'actions', label: 'Acciones' },
      ]"
      :body="body"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PatientService from '@/services/patientService';

import InputSearch from '@/components/form/InputSearch.vue';
import Table from '@/components/dashboard/user/admin/Table.vue';

export default {
  name: 'Patients',
  components: {
    'input-search': InputSearch,
    'table-component': Table,
  },
  data() {
    return {
      search: '',
      patients: [],
      summaryId: null,
      loading: true,
    };
  },
  created() {
    this.getPatients();
  },
  methods: {
    getPatients() {
      PatientService.getPatientsFromDoctor()
        .then(response => {
          this.patients = response.data.patients;
          this.loading = false;
        })
        .catch(() => {
          console.log('error getting patients');
        });
    },
  },
  computed: {
    ...mapGetters({
      user: ['authentication/user'],
    }),
    body() {
      let temp = this.patients;
      if (this.search != '') {
        temp = temp.filter(e => {
          let name_temp = e.names + ' ' + e.lastnames;
          return name_temp.toLowerCase().includes(this.search.toLowerCase());
        });
      }
      temp = temp.sort(function(a, b) {
        if (a.id > b.id) {
          return -1;
        }
        if (a.id < b.id) {
          return 1;
        }
        return 0;
      });
      // sort patients first
      temp = [
        ...temp.filter(e => e.current_patient),
        ...temp.filter(e => !e.current_patient),
      ];
      return temp.map(e => {
        return {
          patient: [
            { label: e.names + ' ' + e.lastnames, class: 'capitalize' },
            { label: e.email, class: 'text-gray-600 lowercase' },
            {
              label: 'contacto: ' + e.patient.contact,
              class: 'text-gray-600 lowercase',
            },
          ],
          identity: [
            { label: e.patient.phone },
            {
              label: e.current_patient ? 'Mi paciente' : 'No es mi paciente',
              class: e.current_patient
                ? 'w-auto text-green-900 bg-green-100 rounded-md px-2 mt-1 lowercase'
                : 'w-auto text-red-900 bg-red-100 rounded-md px-2 mt-1 lowercase',
            },
          ],
          actions: [
            {
              label: 'ficha clínica',
              class:
                'bg-gray-100 hover:bg-gray-200 px-2 py-1 rounded-md mb-2 w-full',
              action: () => {
                this.$router.push({
                  path: `/superdoctor/doctor/summary/clinic/${e.id}`,
                });
              },
            },
            {
              label: 'reservas',
              class:
                'bg-gray-100 hover:bg-gray-200 px-2 py-1 rounded-md mb-2 w-full',
              action: () => {
                this.$router.push({
                  path: `/superdoctor/doctor/reservations/${e.id}`,
                });
              },
            },
          ],
        };
      });
    },
  },
};
</script>
