<template>
  <div class="w-full mb-4 flex flex-row justify-center items-center">
    <div class="flex rounded-md w-full">
      <span
        class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-200 bg-gray-50 text-gray-500 text-sm py-3"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 text-gray-500"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            clip-rule="evenodd"
          />
        </svg>
      </span>
      <input
        type="text"
        v-model="text"
        placeholder="Buscar"
        name="search-box"
        id="company-website"
        class="focus:ring-green-500 focus:border-green-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-200"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      text: '',
    };
  },
  watch: {
    text() {
      this.$emit('input', this.text);
    },
  },
};
</script>
